<template>
  <b-container class="mt-4">
    <b-row>
      <b-col>
        <InputTile
          label="İş Telefon No"
          v-model="form.contact.workPhoneNumber"
          type="text"
        />
        <InputTile label="İş Adres" :custom="true">
          <b-textarea
            v-model="form.contact.workAddress"
            rows="2"
            max-rows="2"
          ></b-textarea>
        </InputTile>
        <InputTile
          label="Ev Telefon No"
          v-model="form.contact.homePhoneNumber"
          type="text"
        />
        <InputTile label="Ev Adres" :custom="true">
          <b-textarea
            v-model="form.contact.homeAdress"
            rows="2"
            max-rows="2"
          ></b-textarea>
        </InputTile>
        <InputTile label="Yaşadığı İl" :custom="true">
          <b-form-select
            v-model="form.contact.cityWhereLocated"
            :options="cities"
            required
          >
          </b-form-select>
        </InputTile>
      </b-col>
      <b-col>
        <InputTile
          label="Yaşadığı İlçe"
          v-model="form.contact.districtWhereLocated"
          type="text"
        />
        <InputTile
          label="Cep Telefon No"
          v-model="form.contact.gsm"
          type="text"
        />
        <InputTile
          label="E-Posta Adresi"
          v-model="form.contact.email"
          type="text"
        />
        <InputTile
          label="Web Adresi"
          v-model="form.contact.website"
          type="text"
        />
        <InputTile label="Banka Bilgileri" :custom="true">
          <b-textarea
            v-model="form.contact.bankInfo"
            rows="2"
            max-rows="2"
          ></b-textarea>
        </InputTile>
        <InputTile label="Mesaj Sistemi" :custom="true">
          <b-checkbox v-model="form.contact.message"> </b-checkbox>
        </InputTile>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import InputTile from "../common/InputTile.vue";
import cities from "../../../store/constants/cities";
export default {
  components: { InputTile },
  name: "ContactForm",
  data() {
    return {
      cities: cities,
    };
  },
  props: ["form"],
  methods: {},
};
</script>

<style scoped>
</style>