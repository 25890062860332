<template>
  <b-container class="mt-4">
    <b-row>
      <b-col>
        <InputTile
          label="Şifre"
          v-model="form.system.password"
          required
          type="text"
        />
      </b-col>
      <b-col> </b-col>
    </b-row>
  </b-container>
</template>

<script>
import InputTile from "../common/InputTile.vue";
export default {
  components: { InputTile },
  name: "SystemForm",
  props: ["form"],
};
</script>

<style scoped>
</style>