<template>
  <b-container class="mt-4">
    <b-row>
      <b-col>
        <InputTile label="Kulüp" :custom="true">
          <b-form-select
            v-model="form.branch.clubId"
            :options="clubs"
            text-field="name"
            value-field="id"
          >
          </b-form-select>
        </InputTile>
        <InputTile label="İkinci Kulüp" :custom="true">
          <b-form-select
            v-model="form.branch.secondClubId"
            :options="clubs"
            text-field="name"
            value-field="id"
          >
          </b-form-select>
        </InputTile>
        <InputTile label="Boy" v-model="form.branch.size" type="text" />
        <InputTile label="Kilo" v-model="form.branch.weight" type="text" />
        <InputTile label="Beden" v-model="form.branch.bodySize" type="text" />
        <InputTile
          label="Ayakkabı No"
          v-model="form.branch.shoeSize"
          type="text"
        />
        <InputTile
          label="Spor Ayakkabı No"
          v-model="form.branch.sportsShoeSize"
          type="text"
        />
        <InputTile label="Kategori" :custom="true">
          <b-form-select
            v-model="form.branch.category"
            :options="getCategorySelections"
            text-field="title"
            value-field="id"
          >
          </b-form-select>
        </InputTile>
        <InputTile label="Tekne Sınıfı" :custom="true">
          <b-form-select
            v-model="form.branch.boatClass"
            :options="getBoatClassSelections"
            text-field="title"
            value-field="id"
          >
          </b-form-select>
        </InputTile>
        <InputTile label="Sporcu" :custom="true">
          <b-form-select
            v-model="form.branch.sportState"
            :options="options.activityOptions"
          >
          </b-form-select>
        </InputTile>
        <InputTile
          label="Sporcu Lisans No"
          v-model="form.branch.sportLicenceNumber"
          type="text"
        />
        <InputTile label="Hakem" :custom="true">
          <b-form-select
            v-model="form.branch.sportRefereeState"
            :options="options.activityOptions"
          >
          </b-form-select>
        </InputTile>
        <InputTile label="Ferdi Sporcu" :custom="true">
          <b-form-select
            v-model="form.branch.individual"
            :options="options.activityOptions"
          >
          </b-form-select>
        </InputTile>
        <InputTile
          label="Durgunsu/Aday Hakem Ter. Tar."
          v-model="form.branch.stillWaterCandidateRefereePromotionDate"
          type="date"
        />
        <InputTile
          label="Durgunsu/Bölge Hakem Ter. Tar."
          v-model="form.branch.stillWaterDistrictRefereePromotionDate"
          type="date"
        />
        <InputTile
          label="Durgunsu/Milli Hakem Ter. Tar."
          v-model="form.branch.stillWaterNationalRefereePromotionDate"
          type="date"
        />
        <InputTile
          label="Durgunsu/Uluslararası Hakem Ter. Tar."
          v-model="form.branch.stillWaterInternationalRefereePromotionDate"
          type="date"
        />
        <InputTile
          label="Akarsu/Aday Hakem Ter. Tar."
          v-model="form.branch.runningWaterCandidateRefereePromotionDate"
          type="date"
        />
        <InputTile
          label="Akarsu/Bölge Hakem Ter. Tar."
          v-model="form.branch.runningWaterDistrictRefereePromotionDate"
          type="date"
        />
        <InputTile
          label="Akarsu/Milli Hakem Ter. Tar."
          v-model="form.branch.runningWaterNationalRefereePromotionDate"
          type="date"
        />
        <InputTile
          label="Akarsu/Uluslararası Hakem Ter. Tar."
          v-model="form.branch.runningWaterInternationalRefereePromotionDate"
          type="date"
        />
        <InputTile
          label="Deniz Kanosu/Aday Hakem Ter. Tar."
          v-model="form.branch.seaKayakingCandidateRefereePromotionDate"
          type="date"
        />
        <InputTile
          label="Deniz Kanosu/Bölge Hakem Ter. Tar."
          v-model="form.branch.seaKayakingDistrictRefereePromotionDate"
          type="date"
        />
        <InputTile
          label="Deniz Kanosu/Milli Hakem Ter. Tar."
          v-model="form.branch.seaKayakingNationalRefereePromotionDate"
          type="date"
        />
        <InputTile
          label="Deniz Kanosu/Uluslararası Hakem Ter. Tar."
          v-model="form.branch.seaKayakingInternationalRefereePromotionDate"
          type="date"
        />
        <InputTile
         label="Rafting/Aday Hakem Ter. Tar."
          v-model="form.branch.raftingCandidateRefereePromotionDate"
          type="date"
        />
        <InputTile
          label="Rafting/Bölge Hakem Ter. Tar."
          v-model="form.branch.raftingDistrictRefereePromotionDate"
          type="date"
        />
        <InputTile
          label="Rafting/Milli Hakem Ter. Tar."
          v-model="form.branch.raftingNationalRefereePromotionDate"
          type="date"
        />
        <InputTile
          label="Rafting/Uluslararası Hakem Ter. Tar."
          v-model="form.branch.raftingInternationalRefereePromotionDate"
          type="date"
        />
        <InputTile
          label="Dragon Boat/Aday Hakem Ter. Tar."
          v-model="form.branch.dragonBoatCandidateRefereePromotionDate"
          type="date"
        />
        <InputTile
          label="Dragon Boat/Bölge Hakem Ter. Tar."
          v-model="form.branch.dragonBoatDistrictRefereePromotionDate"
          type="date"
        />
        <InputTile
          label="Dragon Boat/Milli Hakem Ter. Tar."
          v-model="form.branch.dragonBoatNationalRefereePromotionDate"
          type="date"
        />
        <InputTile
          label="Dragon Boat/Uluslararası Hakem Ter. Tar."
          v-model="form.branch.dragonBoatInternationalRefereePromotionDate"
          type="date"
        />

      </b-col>
      <b-col>
        <InputTile label="Antrenör" :custom="true">
          <b-form-select
            v-model="form.branch.trainerState"
            :options="options.activityOptions"
          >
          </b-form-select>
        </InputTile>
        <InputTile
          label="Durgunsu/Yard. Antrenör Ter. Tar."
          v-model="form.branch.stillWaterAssistantTrainerPromotionDate"
          type="date"
        />
        <InputTile
          label="Durgunsu/Antrenör Ter. Tar."
          v-model="form.branch.stillWaterTrainerPromotionDate"
          type="date"
        />
        <InputTile
          label="Durgunsu/Kıdemli Antrenör Ter. Tar."
          v-model="form.branch.stillWaterSeniorTrainerPromotionDate"
          type="date"
        />
        <InputTile
          label="Durgunsu/Baş Antrenör Ter. Tar."
          v-model="form.branch.stillWaterHeadTrainerPromotionDate"
          type="date"
        />
        <InputTile
          label="Durgunsu/Teknik Direktör Ter. Tar."
          v-model="form.branch.stillWaterCoachPromotionDate"
          type="date"
        />
        <InputTile
          label="Akarsu/Yard. Antrenör Ter. Tar."
          v-model="form.branch.runningWaterAssistantTrainerPromotionDate"
          type="date"
        />
        <InputTile
          label="Akarsu/Antrenör Ter. Tar."
          v-model="form.branch.runningWaterTrainerPromotionDate"
          type="date"
        />
        <InputTile
          label="Akarsu/Kıdemli Antrenör Ter. Tar."
          v-model="form.branch.runningWaterSeniorTrainerPromotionDate"
          type="date"
        />
        <InputTile
          label="Akarsu/Baş Antrenör Ter. Tar."
          v-model="form.branch.runningWaterHeadTrainerPromotionDate"
          type="date"
        />
        <InputTile
          label="Akarsu/Teknik Direktör Ter. Tar."
          v-model="form.branch.runningWaterCoachPromotionDate"
          type="date"
        />
        <InputTile
          label="Deniz Kanosu/Yard. Antrenör Ter. Tar."
          v-model="form.branch.seaKayakingAssistantTrainerPromotionDate"
          type="date"
        />
        <InputTile
          label="Deniz Kanosu/Antrenör Ter. Tar."
          v-model="form.branch.seaKayakingTrainerPromotionDate"
          type="date"
        />
        <InputTile
          label="Deniz Kanosu/Kıdemli Antrenör Ter. Tar."
          v-model="form.branch.seaKayakingSeniorTrainerPromotionDate"
          type="date"
        />
        <InputTile
          label="Deniz Kanosu/Baş Antrenör Ter. Tar."
          v-model="form.branch.seaKayakingHeadTrainerPromotionDate"
          type="date"
        />
        <InputTile
          label="Deniz Kanosu/Teknik Direktör Ter. Tar."
          v-model="form.branch.seaKayakingCoachPromotionDate"
          type="date"
        />
        <InputTile
          label="Rafting/Yard. Antrenör Ter. Tar."
          v-model="form.branch.raftingAssistantTrainerPromotionDate"
          type="date"
        />
        <InputTile
          label="Rafting/Antrenör Ter. Tar."
          v-model="form.branch.raftingTrainerPromotionDate"
          type="date"
        />
        <InputTile
          label="Rafting/Kıdemli Antrenör Ter. Tar."
          v-model="form.branch.raftingSeniorTrainerPromotionDate"
          type="date"
        />
        <InputTile
          label="Rafting/Baş Antrenör Ter. Tar."
          v-model="form.branch.raftingHeadTrainerPromotionDate"
          type="date"
        />
        <InputTile
          label="Rafting/Teknik Direktör Ter. Tar."
          v-model="form.branch.raftingCoachPromotionDate"
          type="date"
        />
        <InputTile
          label="Dragon Boat/Yard. Antrenör Ter. Tar."
          v-model="form.branch.dragonBoatAssistantTrainerPromotionDate"
          type="date"
        />
        <InputTile
          label="Dragon Boat/Antrenör Ter. Tar."
          v-model="form.branch.dragonBoatTrainerPromotionDate"
          type="date"
        />
        <InputTile
          label="Dragon Boat/Kıdemli Antrenör Ter. Tar."
          v-model="form.branch.dragonBoatSeniorTrainerPromotionDate"
          type="date"
        />
        <InputTile
          label="Dragon Boat/Baş Antrenör Ter. Tar."
          v-model="form.branch.dragonBoatHeadTrainerPromotionDate"
          type="date"
        />
        <InputTile
          label="Dragon Boat/Teknik Direktör Ter. Tar."
          v-model="form.branch.dragonBoatCoachPromotionDate"
          type="date"
        />

        <InputTile label="İl Temsilcisi" :custom="true">
          <b-form-select
            v-model="form.branch.provincialRepresentative"
            :options="options.activityOptions"
          >
          </b-form-select>
        </InputTile>
        <InputTile label="Kurul Üyesi" :custom="true">
          <b-form-select
            v-model="form.branch.assemblyMember"
            :options="options.activityOptions"
          >
          </b-form-select>
        </InputTile>
        <InputTile label="Kurul" :custom="true">
          <b-form-select
            v-model="form.branch.assembly"
            :options="options.boardOptions"
          >
          </b-form-select>
        </InputTile>
        <InputTile label="Personel" :custom="true">
          <b-form-select
            v-model="form.branch.personel"
            :options="options.activityOptions"
          >
          </b-form-select>
        </InputTile>
        <InputTile
          label="Personel Görev"
          v-model="form.branch.personelDuty"
          type="text"
        />
        <InputTile label="Yönetici" :custom="true">
          <b-form-select
            v-model="form.branch.manager"
            :options="options.activityOptions"
          >
          </b-form-select>
        </InputTile>
        <InputTile
          label="Yönetici Görev"
          v-model="form.branch.managerDuty"
          type="text"
        />
        <InputTile label="Açıklama" :custom="true">
          <b-textarea
            v-model="form.branch.description"
            rows="3"
            max-rows="3"
          ></b-textarea>
        </InputTile>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import InputTile from "@/components/forms/common/InputTile.vue";
import { mapGetters } from "vuex";
import selectionConstants from "@/store/constants/selections";
import options from "@/store/constants/personel";
export default {
  components: { InputTile },
  name: "PersonalForm",
  created() {
    this.$store.dispatch("initManagement");
    this.$store.dispatch("getClubNames");
  },
  computed: {
    ...mapGetters({
      getSelections: "getManagementItems",
      getClubNames: "getClubNames",
    }),
    getBoatClassSelections: {
      get() {
        return [{ title: "Seçin", id: "" }].concat(
          this.getSelections.filter((e) => {
            return e.category == selectionConstants.BOAT_CLASS; //BOAT FILTER
          })
        );
      },
    },
    getCategorySelections: {
      get() {
        return [{ title: "Seçin", id: "" }].concat(
          this.getSelections.filter((e) => {
            return e.category == selectionConstants.CATEGORY; //CATEGORY FILTER
          })
        );
      },
    },
    getBranchSelections: {
      get() {
        return [{ title: "Seçin", id: "" }].concat(
          this.getSelections.filter((e) => {
            return e.category == selectionConstants.BRANCH; //BRANCH FILTER
          })
        );
      },
    },
    clubs: {
      get() {
        return [{ name: "Seçin", id: 0 }].concat(this.getClubNames);
      },
    },
  },
  data() {
    return {
      options: options,
    };
  },
  props: ["form"],
};
</script>

<style scoped>
</style>