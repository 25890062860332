<template>
  <b-container class="mt-4">
    <b-row>
      <b-col>
        <InputTile label="Öğrenim Durumu" :custom="true">
          <b-form-select
            v-model="form.education.educationStatus"
            :options="educationOptions"
          >
          </b-form-select>
        </InputTile>
        <InputTile label="Okul Adı/Bölüm/No" :custom="true">
          <b-textarea
            v-model="form.education.schoolDepartmentNo"
            rows="2"
            max-rows="2"
          ></b-textarea>
        </InputTile>
        <InputTile
          label="En Son Öğrenim Gördüğü Okul"
          v-model="form.education.lastSchoolAttended"
          type="text"
        />
        <InputTile
          label="Yabancı Dil"
          v-model="form.education.foreignLanguage"
          type="text"
        />
      </b-col>
      <b-col> </b-col>
    </b-row>
  </b-container>
</template>

<script>
import InputTile from "../common/InputTile.vue";
import { educationOptions } from "@/store/constants/personel";
export default {
  components: { InputTile },
  name: "EducationForm",
  data() {
    return {
      educationOptions: educationOptions,
    };
  },
  props: ["form"],
};
</script>

<style scoped>
</style>