<template>
  <b-container class="mt-4">
    <b-row>
      <b-col>
        <InputTile
          label="TC Kimlik No"
          v-model="form.ssn"
          type="text"
          required
        />

        <InputTile label="Soyadı" v-model="form.surname" required type="text" />
        <InputTile label="Adı" v-model="form.name" required type="text" />
      </b-col>
      <b-col>
        <InputTile label="Cinsiyeti" :custom="true">
          <b-form-select
            v-model="form.gender"
            :options="genderOptions"
            required
          >
          </b-form-select>
        </InputTile>

        <InputTile label="Doğum Tarihi" v-model="form.birthDate" type="date" />
        <InputTile label="Resim Adresi" :custom="true">
          <b-form-file
            v-model="selectedPicture.file"
            :placeholder="selectedPicture.fileName"
            drop-placeholder="Buraya bırakın..."
            browse-text="Dosya Seç"
          ></b-form-file>
        </InputTile>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import InputTile from "../common/InputTile.vue";
import * as options from "@/store/constants/personel.js";
export default {
  components: { InputTile },
  name: "PersonalForm",
  data() {
    return {
      genderOptions: options.genderOptions,
    };
  },
  props: ["form", "selectedPicture"],
};
</script>

<style scoped>
</style>